/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import { N200, N800 } from '@atlaskit/theme/colors';
export const gridSize = 8; // import { gridSize } from "@atlaskit/theme/constants";
export const borderRadius = 3; // import { borderRadius } from "@atlaskit/theme/constants";
export const fontFamily =
	"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
export const monospaceFontFamily =
	"'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace";

// TODO(brizvash): move it to separate package or check is atlaskit will become compatible with compiled
export const layersLayer = 400; // layers.layer()
export const layersDialog = 300; // layers.dialog()
export const layersNavigation = 200; // layers.navigation()

export const h500 = `
  color: var(--ds-text, ${N800});
  font-size: ${16 / 14}em;
  font-weight: 600;
  letter-spacing: -0.006em;
  line-height: ${20 / 16};
  margin-top: ${gridSize * 3}px;
`;

export const h500Compiled = {
	color: `var(--ds-text, ${N800})`,
	fontSize: `${16 / 14}em`,
	fontWeight: 600,
	letterSpacing: '-0.006em',
	lineHeight: 20 / 16,
	marginTop: `${gridSize * 3}px`,
};

export const h400 = `
  color: var(--ds-text, ${N800});
  font-size: 1em;
  font-weight: 600;
  letter-spacing: -0.003em;
  line-height: ${16 / 14};
  margin-top: ${gridSize * 2}px;
`;

export const h400Compiled = {
	color: `var(--ds-text, ${N800})`,
	fontSize: '1em',
	fontWeight: 600,
	letterSpacing: '-0.003em',
	lineHeight: 16 / 14,
	marginTop: `${gridSize * 2}px`,
};

export const h300 = `
  color: var(--ds-text, ${N800});
  font-size: ${12 / 14}em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: ${16 / 12};
  text-transform: uppercase;
  margin-top: ${gridSize * 2.5}px;
`;

export const h300Compiled = {
	color: `var(--ds-text, ${N800})`,
	fontSize: `${12 / 14}em`,
	fontWeight: 600,
	letterSpacing: 0,
	lineHeight: 16 / 12,
	textTransform: 'uppercase',
	marginTop: `${gridSize * 2.5}px`,
};

export const h200 = `
  color: var(--ds-text-subtlest, ${N200});
  font-size: ${12 / 14}em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: ${16 / 12};
  margin-top: ${gridSize * 2}px;
`;

export const h200Compiled = {
	color: `var(--ds-text-subtlest, ${N200})`,
	fontSize: `${12 / 14}em`,
	fontWeight: 600,
	letterSpacing: 0,
	lineHeight: 16 / 12,
	marginTop: `${gridSize * 2}px`,
};

export const h100 = `
  color: var(--ds-text-subtlest, ${N200});
  font-size: ${11 / 14}em;
  font-weight: 700;
  letter-spacing: 0;
  line-height: ${16 / 11};
  margin-top: ${gridSize * 2}px;
`;

export const h100Compiled = {
	color: `var(--ds-text-subtlest, ${N200})`,
	fontSize: `${11 / 14}em`,
	fontWeight: 700,
	letterSpacing: 0,
	lineHeight: 16 / 11,
	marginTop: `${gridSize * 2}px`,
};
