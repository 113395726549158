import React, { memo, useEffect, useState, Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';
import { Subscribe } from 'unstated';

import { N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import {
	VIEW_PAGE_EXPERIENCE,
	useExperienceState,
	getTabInactivityTracker,
} from '@confluence/experience-tracker';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import { BannerStateContainer } from '@confluence/banners';

import { FadingSpan, StyledRefreshLink, StyledText } from './BrowserIndicators';

const i18n = defineMessages({
	pageWasBackgrounded: {
		id: 'browser-status-indicator.page.was.backgrounded',
		description:
			'Message shown when the page was in a background (non active) tab for too long on initial load',
		defaultMessage:
			'This page was in the background for too long and may not have fully loaded. Try to {refreshLink}',
	},
	refreshLink: {
		id: 'browser-status-indicator.refresh.the.page',
		description: 'Message asking the user to refresh the page',
		defaultMessage: 'refresh the page',
	},
});

const BANNER_NAME = 'PageWasInactiveBanner';
const BANNER_HEIGHT = 32;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageWasInactiveBannerContainer = styled.div({
	width: '100%',
	height: `${BANNER_HEIGHT}px`,
	display: 'flex',
	backgroundColor: token('color.background.accent.gray.subtlest', N20),
	justifyContent: 'center',
	alignItems: 'center',
});

const RefreshLink = () => (
	<StyledRefreshLink target="_blank" onClick={() => window.location.reload()}>
		<FormattedMessage {...i18n.refreshLink}>
			{(text) => <Fragment>{text}</Fragment>}
		</FormattedMessage>
	</StyledRefreshLink>
);

// Experiences to track
const EXPERIENCES = [VIEW_PAGE_EXPERIENCE];

// Delay before making this banner become visible
// when viewing a tab that was previously hidden.
const ON_VIEW_TAB_DELAY = 1000;

type PageWasInactiveBannerComponentProps = {
	bannerState: BannerStateContainer;
};

export const PageWasInactiveBannerComponent = memo(
	({ bannerState }: PageWasInactiveBannerComponentProps) => {
		const [documentVisible, setDocumentVisible] = useState<boolean>(false);

		const experienceStates = EXPERIENCES.map(useExperienceState);

		useEffect(() => {
			const changeVisibility = () => {
				// When a tab's visibility changes, wait a small amount before updating documentVisible.
				// This prevents a flicker of this banner that sometimes occurs
				// when a tab that is loading in the background becomes visible.
				setTimeout(() => {
					setDocumentVisible(!document.hidden);
				}, ON_VIEW_TAB_DELAY);
			};
			document.addEventListener('visibilitychange', changeVisibility);
			return () => document.removeEventListener('visibilitychange', changeVisibility);
		});

		const showBanner =
			documentVisible &&
			experienceStates.some((experienceState) => {
				// dont show the banner if:
				// !experienceState - if an experience has not started
				// !experienceState.timeout - if the experience does not rely on timeout
				if (!experienceState || !experienceState.timeout) {
					return false;
				}

				const { startTime = 0, timeout, hasStopped } = experienceState;

				const absoluteDuration = Math.round(window.performance.now() - startTime);
				const adjustedTabActiveTime =
					absoluteDuration - getTabInactivityTracker().getInactiveMillisecondsSince(startTime);
				const insufficientActiveTime = adjustedTabActiveTime < timeout;
				return !hasStopped && insufficientActiveTime;
			});

		if (showBanner) {
			bannerState.show(BANNER_NAME, BANNER_HEIGHT);
			return (
				<PageWasInactiveBannerContainer data-testid="inactive-indicator">
					<FadingSpan show>
						<StyledText>
							<FormattedMessage
								{...i18n.pageWasBackgrounded}
								values={{ refreshLink: <RefreshLink /> }}
							/>
						</StyledText>
					</FadingSpan>
				</PageWasInactiveBannerContainer>
			);
		} else {
			bannerState.hide(BANNER_NAME);
			return null;
		}
	},
);

export const PageWasInactiveBanner = withTransparentErrorBoundary({
	attribution: Attribution.PAGE_MODES,
})(() => {
	return (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				return <PageWasInactiveBannerComponent bannerState={bannerState} />;
			}}
		</Subscribe>
	);
});
