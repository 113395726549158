import { LoadableAfterPaint } from '@confluence/loadable';

export const PreviouslyTrialedUserBanner = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PreviouslyTrialedUserBanner" */ '../PreviouslyTrialedUserBanner/PreviouslyTrialedUserBanner'
			)
		).PreviouslyTrialedUserBanner,
});
