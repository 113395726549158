import { LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';

export const DevToolsDialog = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-DevToolsDialog" */ './DevToolsDialog'))
			.DevToolsDialog,
});

export const DevToolsBanner = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-DevToolsBanner" */ './DevToolsBanner'))
			.DevToolsBanner,
});
