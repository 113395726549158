import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import { useIsStorageEnforcementEligible } from '../hooks/useIsStorageEnforcementEligible';

const StorageEnforcementBannerLoadable = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-StorageEnforcementBanner" */ './StorageEnforcementBanner'
			)
		).StorageEnforcementBanner,
});

export const StorageEnforcementBanner = () => {
	const isEligibleUser = useIsStorageEnforcementEligible();
	return isEligibleUser ? <StorageEnforcementBannerLoadable /> : null;
};
