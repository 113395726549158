import type { Dispatch } from 'react';
import React, { useEffect, useState } from 'react';
import { Subscribe } from 'unstated';
import { defineMessages, useIntl } from 'react-intl-next';
import UAParser from 'ua-parser-js';

import { BannerStateContainer } from '@confluence/banners';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { DismissibleBanner } from '@confluence/banners/entry-points/DismissibleBanner';
import {
	confluenceSessionStorageInstance as sessionStorage,
	keys as storageKeys,
} from '@confluence/storage-manager';
import { gridSize } from '@confluence/typography-placeholder';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { isNativeMobile, setUserAgent } from '@confluence/browser-helper';

import { showBrowserRuntimeWarning } from '@devtools/browser-check';

const BANNER_HEIGHT = gridSize * 9; // 72px when gridSize = 8px
const BANNER_NAME = 'UnsupportedBrowserBanner';

const messages = defineMessages({
	bannerMessage: {
		id: 'unsupported-browser-banner.banner-message',
		defaultMessage:
			'Your browser is <link>unsupported</link>. Switch or upgrade to maintain optimal performance.',
		description:
			'Warning for users with unsupported browser versions who may not experience optimal performance.',
	},
	bannerLink: {
		id: 'unsupported-browser-banner.banner-link',
		defaultMessage: 'unsupported',
		description: 'Text for link in banner',
	},
});

const BannerLink = (str: React.ReactNode[]) => (
	<a
		href="https://support.atlassian.com/atlassian-account/docs/supported-browsers-for-atlassian-cloud-products/"
		target="_blank"
		rel="noreferrer noopener"
	>
		{str}
	</a>
);

type BannerComponentProps = {
	bannerState: BannerStateContainer;
	setIsBannerClosed: Dispatch<boolean>;
	isBannerClosed: boolean;
};

const UnsupportedBrowserBannerComponent = ({
	bannerState,
	setIsBannerClosed,
	isBannerClosed,
}: BannerComponentProps) => {
	const intl = useIntl();

	useEffect(() => {
		const bannerClosedByUser = sessionStorage.getItemAsBoolean(
			storageKeys.BROWSER_BANNER_CLOSED_BY_USER,
		);
		if (bannerClosedByUser) {
			setIsBannerClosed(true);
			bannerState.hide(BANNER_NAME);
		}
	}, [setIsBannerClosed, isBannerClosed, bannerState]);

	return (
		<DismissibleBanner
			height={BANNER_HEIGHT}
			isWarning
			onDismiss={() => {
				bannerState.hide(BANNER_NAME);
				setIsBannerClosed(true);
				sessionStorage.setItem(storageKeys.BROWSER_BANNER_CLOSED_BY_USER, true);
			}}
		>
			{intl.formatMessage(messages.bannerMessage, {
				link: BannerLink,
			})}
		</DismissibleBanner>
	);
};

export const UnsupportedBrowserBanner = () => {
	const [isBannerClosed, setIsBannerClosed] = useState<boolean>(true);
	const failedFullCapabilitiesBrowserCheck = showBrowserRuntimeWarning();

	// Must call this before any browser-helper functions, to ensure user agent has been set first
	useEffect(setUserAgent, []);

	// Display banner if full browser capabilities check failed
	useEffect(() => {
		if (failedFullCapabilitiesBrowserCheck) {
			setIsBannerClosed(false);
		}
	}, [failedFullCapabilitiesBrowserCheck, setIsBannerClosed]);

	const userAgentParser = new UAParser(navigator.userAgent);
	const { name: browserName, version: browserVersion } = userAgentParser.getBrowser();

	// collect analytics
	useEffect(() => {
		void getAnalyticsWebClient().then((client) => {
			client.sendOperationalEvent({
				source: 'unsupportedBrowserBanner',
				action: 'executed',
				actionSubject: 'browserRuntimeCheck',
				attributes: {
					failedFullBrowserCheck: failedFullCapabilitiesBrowserCheck,
					browserName,
					browserVersion,
				},
			});
		});
	}, [failedFullCapabilitiesBrowserCheck, browserName, browserVersion]);

	if (
		isBannerClosed ||
		!failedFullCapabilitiesBrowserCheck ||
		isNativeMobile() // Do not render the banner in the Confluence native mobile app
	) {
		return null;
	}

	return (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			<Subscribe to={[BannerStateContainer]}>
				{(bannerState: BannerStateContainer) => {
					bannerState.show(BANNER_NAME, BANNER_HEIGHT);
					return (
						<UnsupportedBrowserBannerComponent
							bannerState={bannerState}
							setIsBannerClosed={setIsBannerClosed}
							isBannerClosed={isBannerClosed}
						/>
					);
				}}
			</Subscribe>
		</ErrorBoundary>
	);
};
