export const getCurrentRemSize = () => {
	const fallback = 16; // 16px is the default rem size for most browsers
	if (typeof document === 'undefined' || typeof getComputedStyle === 'undefined') {
		return fallback;
	}
	return parseFloat(getComputedStyle(document.documentElement).fontSize) || fallback;
};

export const convertRemToPixel = (tokenValue: string) => {
	if (!tokenValue.includes('rem')) {
		return parseInt(tokenValue);
	}
	return parseInt(tokenValue) * getCurrentRemSize();
};
