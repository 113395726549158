import { useState, useCallback, useEffect, useRef, useMemo } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { shouldRenderBannerPrefetched } from '@atlassian/browser-storage-controls';
import type { PrefetchedConsentPreferences } from '@atlassian/browser-storage-controls';

import { ssrBannerManager } from '@confluence/ssr-utilities';
import type { BannerStateContainer } from '@confluence/banners';

import { MIN_BANNER_HEIGHT, BANNER_NAME } from './constants';

declare global {
	interface Window {
		__COOKIE_CONSENT_PREFERENCES__:
			| Promise<PrefetchedConsentPreferences | null>
			| PrefetchedConsentPreferences
			| null;
		__SSR_RENDER_PARAMS__: Record<string, any>;
	}
}

export function useCookiesConsentBanner(
	bannerState?: BannerStateContainer | undefined,
	bannerRef?: React.RefObject<HTMLDivElement | null>,
) {
	const cookiesDialogRef = useRef<HTMLDivElement | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [height, setHeight] = useState(MIN_BANNER_HEIGHT);
	const [shouldRender, setShouldRender] = useState<boolean | null>(null);
	const prefetchedData =
		(window?.__COOKIE_CONSENT_PREFERENCES__ as PrefetchedConsentPreferences) || undefined;

	const shouldRenderSSR = useMemo(() => {
		try {
			if (prefetchedData && !Object.keys(prefetchedData).length) {
				// If the object is empty, its likely that the best effort fetch failed and we should not render the banner in SSR
				return false;
			}

			return (
				//@ts-ignore
				shouldRenderBannerPrefetched(prefetchedData)
			);
		} catch {
			return false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (process.env.REACT_SSR && shouldRenderSSR && window?.__COOKIE_CONSENT_PREFERENCES__) {
		ssrBannerManager.registerBanner({
			name: BANNER_NAME,
			height: MIN_BANNER_HEIGHT,
		});
		bannerState?.show(BANNER_NAME, height);
	}

	if (shouldRender) {
		bannerState?.show(BANNER_NAME, height);
	} else if (shouldRender === false) {
		bannerState?.hide(BANNER_NAME);
	}

	const findCookiesDialog = useCallback(() => {
		if (cookiesDialogRef?.current) {
			return cookiesDialogRef?.current;
		}

		if (!bannerRef?.current) {
			return null;
		}
		const childDivs = bannerRef?.current.getElementsByTagName('div');
		let cookiesDialog = null;
		for (const element of Object.values(childDivs)) {
			if (!element.offsetHeight) {
				continue;
			}
			// @ts-ignore - Type 'HTMLDivElement' is not assignable to type 'null'
			// Using `ts-ignore` and not `ts-expect-error` here as TypeScript will fail on this line without `ts-ignore` but with `ts-expect-error`
			// This error was introduced after upgrading to TypeScript 5
			cookiesDialog = element;
			break;
		}
		cookiesDialogRef.current = cookiesDialog;
		return cookiesDialog;
	}, [bannerRef]);

	useEffect(() => {
		if (shouldRender) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				action: 'rendered',
				actionSubject: 'cookiesConsentBanner',
			}).fire();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldRender]);

	const onWidthChange = useCallback(() => {
		const cookiesDialog = findCookiesDialog();
		if (!cookiesDialog) {
			return;
		}
		setHeight(cookiesDialog.offsetHeight);
	}, [findCookiesDialog]);

	const showBanner = useCallback(() => {
		setShouldRender(true);
		onWidthChange();
	}, [onWidthChange]);

	const hideBanner = useCallback(() => {
		setShouldRender(false);
	}, []);

	return {
		shouldRender,
		showBanner,
		hideBanner,
		onWidthChange,
		shouldRenderSSR,
		prefetchedData,
	};
}
