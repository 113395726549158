import { useContext, useState, useEffect } from 'react';

import { ExperienceTrackerContext } from './component';
import type { ExperienceState } from './Experience';

// Returns state of a given experience, if the experience exists
export const useExperienceState = (experienceName: string): ExperienceState | null => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [experienceState, setExperienceState] = useState<ExperienceState | null>(
		experienceTracker.getExperienceState(experienceName),
	);

	useEffect(
		() =>
			experienceTracker.subscribe((e) => {
				if (e.name === experienceName) {
					setExperienceState(experienceTracker.getExperienceState(experienceName));
				}
			}),
		[experienceName, experienceTracker],
	);

	return experienceState;
};
