import { getTokenValue } from '@atlaskit/tokens';
import { gridSize } from '@confluence/typography-placeholder';

import { convertRemToPixel } from './helpers';

const PADDING_FALLBACK = '16px';
const BORDER_FALLBACK = '1px';

// Padding and border values mirror Platform ConsentBanner which defaults to 74px height https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/platform/packages/policy-platform/browser-storage-controls/src/ui/consent-banner/presentational-consent-banner/index.tsx?until=0ae69184856fc16979bf360b0d23f0adbe832d0d&untilPath=platform%2Fpackages%2Fpolicy-platform%2Fbrowser-storage-controls%2Fsrc%2Fui%2Fconsent-banner%2Fpresentational-consent-banner%2Findex.tsx#16
const padding = convertRemToPixel(getTokenValue('space.200', PADDING_FALLBACK));
const borderWidth = convertRemToPixel(getTokenValue('border.width', BORDER_FALLBACK));
export const MIN_BANNER_HEIGHT = gridSize * 5 + padding * 2 + borderWidth * 2;

export const BANNER_NAME = 'CookiesConsentBanner';
