import React, { useRef } from 'react';
import { styled } from '@compiled/react';
import { Subscribe } from 'unstated';
import debounce from 'lodash/debounce';

import { WidthObserver } from '@atlaskit/width-detector';

import { LoadableAfterPaint } from '@confluence/loadable';
import { BannerStateContainer } from '@confluence/banners';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import { useCookiesConsentBanner } from './useCookiesConsentBanner';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Banner = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContent = styled.div({
	flex: 1,
});

const ConsentBannerComponent = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-consent-banner-wrapper" */ './ConsentBannerWrapper'
			)
		).ConsentBannerWrapper,
});

type CookiesConsentBannerProps = {};

type CookiesConsentBannerComponentProps = {
	bannerState: BannerStateContainer;
};

const CookiesConsentBannerComponent: React.FC<CookiesConsentBannerComponentProps> = ({
	bannerState,
}) => {
	const bannerRef = useRef<HTMLDivElement | null>(null);
	const { onWidthChange, showBanner, hideBanner, prefetchedData } = useCookiesConsentBanner(
		bannerState,
		bannerRef,
	);

	const onResize = debounce(onWidthChange, 50);

	return (
		<Banner
			ref={bannerRef}
			role="banner"
			data-testid="cookie-consent-banner"
			data-vc="cookie-consent-banner"
		>
			<BannerContent>
				<WidthObserver setWidth={onResize} />
				<ConsentBannerComponent
					product="Confluence"
					onShow={showBanner}
					onHide={hideBanner}
					initialPreferences={
						prefetchedData && Object.keys(prefetchedData).length ? prefetchedData : undefined
					}
				/>
			</BannerContent>
		</Banner>
	);
};

export const CookiesConsentBanner: React.FC<CookiesConsentBannerProps> = () => {
	return (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			<Subscribe to={[BannerStateContainer]}>
				{(bannerState: BannerStateContainer) => {
					return <CookiesConsentBannerComponent bannerState={bannerState} />;
				}}
			</Subscribe>
		</ErrorBoundary>
	);
};
