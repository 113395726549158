import React from 'react';
import { styled } from '@compiled/react';

import { N20, N100, G50, B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { FULL_PAGE_EDITOR_TOOLBAR_HEIGHT } from '@atlaskit/editor-shared-styles';

import { MAXIMUM_TWO_LINE_TOOLBAR_BREAKPOINT } from '@confluence/editor-common-styles';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Indicator = styled.div<{
	show: boolean;
}>({
	display: 'flex',
	position: 'absolute',
	width: '100%',
	zIndex: 1,
	justifyContent: 'center',
	alignItems: 'center',
	// We are holding on converting FULL_PAGE_EDITOR_TOOLBAR_HEIGHT until active content topper development is complete
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: FULL_PAGE_EDITOR_TOOLBAR_HEIGHT(),
	left: token('space.0', '0px'),
	height: token('space.400', '32px'),
	backgroundColor: token('color.background.accent.gray.subtlest', N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: `scaleY(${(props) => (props.show ? 1 : 0)})`,
	transformOrigin: 'top',
	transition: 'all 500ms ease',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@media (max-width: ${MAXIMUM_TWO_LINE_TOOLBAR_BREAKPOINT}px)`]: {
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		top: FULL_PAGE_EDITOR_TOOLBAR_HEIGHT(),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StatusIndicator = styled(Indicator)<{
	show: boolean;
	showOnlineBackground: boolean;
}>({
	zIndex: 700,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.show && props.showOnlineBackground
			? token('color.background.success', G50)
			: token('color.background.accent.gray.subtlest', N20),
});

// TODO: Figure out why @compiled is having trouble with the @media lambda function below.
// eslint-disable-next-line @compiled/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledText = styled.span<{ mediaMaxWidth?: string }>`
	font-family: inherit;
	font-weight: bold;
	font-size: 12px;
	color: ${token('color.text.subtlest', N100)};
	margin-left: ${token('space.100', '8px')};
	white-space: nowrap;
	overflow: hidden;
	@media (max-width: ${(props) => props.mediaMaxWidth}) {
		width: 80vw;
	}
`;
StyledText.defaultProps = {
	mediaMaxWidth: '650px',
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FadingSpan = (props) => <FadingSpanInternal aria-hidden={!props?.show} {...props} />;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const FadingSpanInternal = styled.span<{ show: boolean }>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.show ? 1 : 0),
	transition: 'opacity 0.5s',
	position: 'absolute',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EllipsisContainer = styled.span({
	display: 'flex',
	alignItems: 'flex-end',
	height: token('space.100', '8px'),
	marginLeft: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AnimatedDot = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes wave': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'0%, 60%, 100%': {
			transform: 'initial',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'30%': {
			transform: 'translateY(-1px)',
		},
	},
	display: 'inline-block',
	width: token('space.025', '2px'),
	height: token('space.025', '2px'),
	borderRadius: '50%',
	marginRight: token('space.025', '2px'),
	background: token('color.text.subtlest', N100),
	animation: 'wave 0.6s linear infinite',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:nth-of-type(2)': {
		animationDelay: '-0.4s',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:nth-of-type(3)': {
		animationDelay: '-0.3s',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledRefreshLink = styled.a({
	fontFamily: 'inherit',
	fontWeight: 'bold',
	fontSize: '11px',
	color: token('color.text.brand', B400),
});
