import { fg } from '@atlaskit/platform-feature-flags';

import { buildConsentPreferences } from '../../../services/consent-hub-service';
import { checkTokenVersionValidity } from '../../cookie-controls/transformer';
import { getBannerVisibility } from '../get-banner-visibility';
import type {
	PrefetchedConsentHubResponseData,
	PrefetchedConsentPreferenceData,
	PrefetchedCookieData,
} from '../types';

/**
  Synchronously uses prefetched data to determine banner visibility.
  Useful for: consumers who need to preallocate UI spacing for SSR.

  * @param prefetchedPreferencesData - Prefetched data, either raw ConsentHub response data, or derived from existing cookies
    If the user is authenticated with `consents`, they will have a consentTimestamp to drive stale consents/banner visibility.

    Optional `fallbackConsentToken` property on this param:
      - If browser cookies are accessible, this data should be pulled from the `atl-bsc-consent-token-fallback` cookie
      - If the user is unauthenticated, the existence of this cookie dictates
        if their consents are stale (> 1yr since consents made), and is used to determine banner visibility.
      - To compute banner state with perfect accuracy, a fallbackConsentToken is required.
        When `fallbackConsentToken` is absent, the banner will make the best effort to hide/show properly,
          but in some situations, this function may inform the consumer that the banner should be shown
          but may end up hidden on the UI when the page rehydrates— this is unavoidable without the fallback token
*/
export const shouldRenderBannerPrefetched = (
	prefetchedPreferencesData?: PrefetchedConsentPreferenceData,
): boolean => {
	if (fg('platform_moonjelly_bsc_ssr_killswitch')) {
		// If the bsc ssr killswitch is enabled, we should not show the banner
		// in SSR context
		return false;
	}

	if (!prefetchedPreferencesData) {
		// If prefetched prefs are missing (presumably SSR prefetching failed), but are still trying to compute
		// banner state, we have no info to calculate from so, we need to show the banner to be compliant
		return true;
	}

	if ('consentToken' in prefetchedPreferencesData) {
		// We've been given cached cookie data, validate it before use.
		// If invalid, we can't determine anything, show the banner for compliance sake
		const { isValid } = checkTokenVersionValidity(prefetchedPreferencesData.consentToken);
		return isValid ? getBannerVisibility(prefetchedPreferencesData as PrefetchedCookieData) : true;
	}

	// We've been given ConsentHub response data, format it appropriately
	const consentPrefsData = buildConsentPreferences(
		prefetchedPreferencesData as PrefetchedConsentHubResponseData,
	);
	return getBannerVisibility(consentPrefsData);
};
