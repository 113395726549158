import { LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';

export const PersistentUpgradeBanner = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PersistentUpgradeBanner" */ './PersistentUpgradeBanner'
			)
		).PersistentUpgradeBanner,
});

export { usePersistentUpgradeMenuSection } from './usePersistentUpgradeMenuSection';

export const PersistentUpgradeButton = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PersistentUpgradeButton" */ './PersistentUpgradeButton/PersistentUpgradeButton'
			)
		).PersistentUpgradeButton,
});

export { usePersistentUpgradeButton } from './PersistentUpgradeButton/usePersistentUpgradeButton';
