import { LoadableLazy } from '@confluence/loadable';

export const EotBanner = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-eot-banner" */
				'../src'
			)
		).EotBannerContainerWithErrorBoundary,
});
